<script>
  // Svelte
  import { createEventDispatcher, getContext } from "svelte";

  // Components
  import ExpenseBadge from "./ExpenseBadge.svelte";
  import IncomeBadge from "./IncomeBadge.svelte";

  // Exports
  export let id, concept, type, category, date, value;

  // Functions
  const dispatch = createEventDispatcher();
  const editTransaction = getContext("modify");
</script>

<div class="transaction">
  <div class="transaction_content">
    {#if type === 'income'}
      <IncomeBadge {category} />
    {:else}
      <ExpenseBadge {category} />
    {/if}
    <div class="transaction_info">
      <h4 class="transaction_concept">{concept}</h4>
      <p class="transaction_date">{date}</p>
    </div>
    <div class="transaction_value">
      {type === 'expenses' ? '-' : ''}{value.toFixed(2)}€
    </div>
  </div>
  <div class="transaction_controls">
    <div
      class="transaction_control edit w-embed"
      on:click={() => editTransaction(id)}>
      <svg
        viewbox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M493.25 56.26L455.74 18.75C443.25 6.25 426.87 0 410.49 0C394.11 0
          377.73 6.25 365.23 18.74L12.85 371.12L0.150022 485.34C-1.44998 499.72
          9.88002 512 23.95 512C24.84 512 25.73 511.95 26.64 511.85L140.78
          499.24L493.26 146.76C518.25 121.77 518.25 81.25 493.25
          56.26V56.26ZM126.09 468.68L33.06 478.99L43.42 385.82L307.31
          121.93L390.08 204.7L126.09 468.68V468.68ZM470.63 124.14L412.7
          182.07L329.93 99.3L387.86 41.37C393.9 35.33 401.94 32 410.49 32C419.04
          32 427.07 35.33 433.12 41.37L470.63 78.88C483.1 91.36 483.1 111.66
          470.63 124.14Z" />
      </svg>
    </div>
    <div
      class="transaction_control delete w-embed"
      on:click={() => dispatch('delete', id)}>
      <svg
        viewbox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M328 432H344C346.122 432 348.157 431.157 349.657 429.657C351.157
          428.157 352 426.122 352 424V152C352 149.878 351.157 147.843 349.657
          146.343C348.157 144.843 346.122 144 344 144H328C325.878 144 323.843
          144.843 322.343 146.343C320.843 147.843 320 149.878 320 152V424C320
          426.122 320.843 428.157 322.343 429.657C323.843 431.157 325.878 432
          328 432ZM168 432H184C186.122 432 188.157 431.157 189.657
          429.657C191.157 428.157 192 426.122 192 424V152C192 149.878 191.157
          147.843 189.657 146.343C188.157 144.843 186.122 144 184
          144H168C165.878 144 163.843 144.843 162.343 146.343C160.843 147.843
          160 149.878 160 152V424C160 426.122 160.843 428.157 162.343
          429.657C163.843 431.157 165.878 432 168 432ZM472 64H368L334.4
          19.2C329.929 13.2386 324.131 8.40003 317.466 5.06749C310.801 1.73496
          303.452 0 296 0L216 0C208.548 0 201.199 1.73496 194.534
          5.06749C187.869 8.40003 182.071 13.2386 177.6 19.2L144 64H40C37.8783
          64 35.8434 64.8429 34.3431 66.3431C32.8429 67.8434 32 69.8783 32
          72V88C32 90.1217 32.8429 92.1566 34.3431 93.6569C35.8434 95.1571
          37.8783 96 40 96H64V464C64 476.73 69.0571 488.939 78.0589
          497.941C87.0606 506.943 99.2696 512 112 512H400C412.73 512 424.939
          506.943 433.941 497.941C442.943 488.939 448 476.73 448
          464V96H472C474.122 96 476.157 95.1571 477.657 93.6569C479.157 92.1566
          480 90.1217 480 88V72C480 69.8783 479.157 67.8434 477.657
          66.3431C476.157 64.8429 474.122 64 472 64ZM203.2 38.4C204.696 36.4186
          206.629 34.8099 208.85 33.6996C211.07 32.5894 213.517 32.0077 216
          32H296C298.483 32.0077 300.93 32.5894 303.15 33.6996C305.371 34.8099
          307.304 36.4186 308.8 38.4L328 64H184L203.2 38.4ZM416 464C416 468.243
          414.314 472.313 411.314 475.314C408.313 478.314 404.243 480 400
          480H112C107.757 480 103.687 478.314 100.686 475.314C97.6857 472.313 96
          468.243 96 464V96H416V464ZM248 432H264C266.122 432 268.157 431.157
          269.657 429.657C271.157 428.157 272 426.122 272 424V152C272 149.878
          271.157 147.843 269.657 146.343C268.157 144.843 266.122 144 264
          144H248C245.878 144 243.843 144.843 242.343 146.343C240.843 147.843
          240 149.878 240 152V424C240 426.122 240.843 428.157 242.343
          429.657C243.843 431.157 245.878 432 248 432Z" />
      </svg>
    </div>
  </div>
</div>
